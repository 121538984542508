.inputDiv {
  height: 100%;

  width: fit-content;

  display: flex;
  align-items: center;

  position: relative;

  margin-top: 30px;

  z-index: 1000;

  margin-left: auto;
  margin-right: auto;
}
.inputDiv .inputLabel {
  position: absolute;
  z-index: 20;

  margin-bottom: auto;
  color: #aeaeae;
  font-size: clamp(10px, 2vh - 0.3vw, 20px);
  padding: 0px 5px;
}
.inputLabel:nth-of-type(-n + 2) {
  top: -30px;
}
.topInputs p {
  top: -40px !important;
}
.toggle {
  --width: clamp(180px, 26vh, 270px);
  --height: calc(var(--width) / 6);

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border: 1px solid #764ef2;

  border-radius: var(--height);
  align-items: center;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: "";
  position: absolute;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: clamp(10px, 2.5vh - 0.1vw, 50px);
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 1px;
  text-transform: capitalize;
  color: #fff;

  top: 0;
  left: 0;

  width: 50%;
  height: 100%;
  z-index: -10;
  border-radius: calc(var(--height) / 2);
  background-color: #764ef2;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked + .slider {
  background-color: transparent;
}

.toggle input:checked + .slider::before {
  transform: translateX(calc(var(--width) / 2));
}

.toggle .switchText {
  position: absolute;

  width: 100%;
  height: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: clamp(1rem, 0.6rem + 0.3762vw, 1.75rem);
  /* line-height: 17px; */
  /* identical to box height */

  letter-spacing: 1px;
  text-transform: capitalize;
  /* display: none; */
  color: #764ef2;
  /* border: 1px solid yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  transition: all 0.4s ease-in-out;
}

.toggle .switchText::after {
  content: attr(data-on);
  position: absolute;
  right: 8px;

  color: #764ef2;
  opacity: 1;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); */
  transition: all 0.4s ease-in-out;
  z-index: 10;
}

.toggle .switchText::before {
  content: attr(data-off);
  position: absolute;
  left: 8px;

  color: #fff;

  opacity: 1;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked ~ .switchText::after {
  color: #fff;
}

.toggle input:checked ~ .switchText::before {
  color: #764ef2;
}
/* Add styles for highlighted text */
.highlightedText {
  position: absolute;
  top: -26px;
  right: 0;
  color: #fff;
  border: 2px solid white;
  background-color: #e72727;
  padding: 5px;
  font-weight: 400;
  border-radius: 5px;
  font-size: 0.8rem;
}
