.menu-wrapper {
  position: relative;
}

.menu-button {
  display: inline-block;
}

.menu {
  z-index: 1;
  position: absolute;
  top: calc(100% - -5px);
  right: calc(100% + -36px);
  width: 156px;
  border: 1px solid #ced0da;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(12, 0, 51, 0.1);
  overflow-x: hidden;
}

.menu-item {
  margin: auto;
  line-height: 40px;
  width: 154px;
  opacity: 0.85;
  list-style-type: none;
  padding-left: 17px;
}

.menu-item:hover {
  background-color: #f1f7fb;
}

.menu-item:focus {
  background-color: #f1f7fb;
}
