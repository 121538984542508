body {
  margin: 0;
  padding: 0;
  background: linear-gradient(
    to bottom right,
    #fcf0fb 0%,
    #e9e3ec 0%,
    #dadce6 100%
  );
  font-family: "Lato", sans-serif;
  font-weight: 300;
  min-height: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

/*style for email tag input in share modal*/
.rti--container {
  width: 100%;
  border: 2px solid #0074d9 !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(22, 22, 22, 0);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(112, 112, 112);
  border-radius: 10px;
}
