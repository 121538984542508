.pagination {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: bolder;
  user-select: none;
}

.pagination > li {
  list-style: none;
  display: inline-block;
  color: rgba(23, 15, 48, 0.5);
  line-height: 20px;
  padding: 5px;
}

.pagination > li:hover {
  color: #764ef2;
  font-weight: bold;
}

.pagination > li > a {
  padding: 8px 16px;
  cursor: pointer;
  outline: 0;
}

.active.active {
  color: #764ef2;
  font-weight: bold;
}

.break.break.break {
  color: rgba(23, 15, 48, 0.5);
  font-weight: bolder;
}
