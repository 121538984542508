.profile-picture {
  width: 100px;
  height: 100px;
  border: 2px solid #ffffff;
  background-color: #764ef2;
  border-radius: 50%;
  margin-top: 51.5px;
  margin-left: auto;
  margin-right: auto;
}

.profile-name {
  width: 100%;
  font-size: 24px;
  margin-top: 22px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: lighter;
}
